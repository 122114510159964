.container {
   padding: 40px;
    background-color: #3457a6 !important;
    color: #fff;
    font-size: 13px;
    background: linear-gradient(307deg, #ffffff, #202dab);
}

.panel {
  text-align: center;
}

.aboutUs,
.termsAndConditions,
.privacyPolicy {
  cursor: pointer;
}

.redSocial {
  color: #fff;
  font-size: 20px;
  padding: 5px;
}

.payU {
  background: url(../../assets/images/header/salud.png),
    url(../../assets/images/header/Wompi_LogoPrincipal.svg);
  background-size: 30%, 70%;
  background-repeat: no-repeat, no-repeat;
  background-position: center left, center right;
  height: 107px;
}

.icon {
  margin-right: 10px;
}
