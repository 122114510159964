.rowSpecialist {
  border: 1px solid #7c45ac;
  padding: 5px;
  margin: 10px;
  border-radius: 20px;
}

.photo {
  width: 100px;
  height: 100px;
  border-radius: 85px;
}
