.panel {
  display: flex;
  height: 100vh;
  width: 100vw; /* Asegúrate de usar toda la anchura de la ventana */
}

.panelComponents {
  width: calc(100% - 250px); /* Ajusta según el ancho de tu SideNav */
  height: calc(100% - 60px); /* Ajusta según la altura de tu Header */
  margin-top: 60px; /* Altura del Header */
  margin-left: o; /* Ancho del SideNav */
  overflow-y: auto; /* Permite el scroll en el área de los componentes */
}