.title-primary {
  color: #034c8c;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
}

.title-secundary {
  font-size: 13px;
  color: #034c8c;
  font-weight: 600;
  margin-bottom: 15px;
  border-bottom: 1px dotted #034c8c73;
  padding-bottom: 7px;
  width: 100%;
}

legend {
  font-size: 13px;
  color: #034c8c;
  font-weight: 600;
  margin-bottom: 15px;
}

fieldset {
  border: 1px solid #034c8c;
  border-radius: 10px;
  padding: 20px;
}

.photo {
  width: 80px;
  height: 80px;
  border-radius: 100px;
}

.form-check {
  display: inline-block;
}

.payu .form-check-label {
  background-image: url(../../assets/icons/commerce/payu-corporate-logo-vector-2022.svg);
  margin-right: 31px;
  background-size: 101px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: left -24px;
  height: 50px;
  width: 100px;
  position: relative;
  font-size: 0px;
  top: -31px;
}

.title-payu {
  background: #034c8c;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 2em;
}

.hr {
  box-shadow: 1px 1px 1px #000;
  margin-bottom: 2em;
}

.payu {
  height: 45px;
}

.obligatorio{
    margin-left: 2px;
    color: #3457a6;
}
.title-obligatory{
    background: #ff0000bf;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
}

.data-table-view-pay{
  overflow: hidden;
  padding-left: 2em;
}

.button-search-viewPay{
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.container-form{
  margin: 1em
}