.container {
  border: 3px solid var(--purple2);
  border-radius: 1.5em;
  padding: 3.5rem;
  background: #ffffff;
  border-top-left-radius: 0px;
  margin-top: -6px;
}

.containeres {
  border: 3px solid var(--purple2);
  border-radius: 1.5em;
  padding: 3.5rem;
  background: #ffffff;
  border-top-right-radius: 0px;
  margin-top: -6px;
}
.container .lg-label,.containeres .lg-label {
  text-align: center;
  color: #0b4e8a;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  cursor: pointer;
}

.container .lg-label:hover  ,.containeres .lg-label:hover {
  text-decoration: underline;
}

.background_patient,
.background_specialist {
  height: 100vh;
  padding-top:8.5%;
  position: absolute;
  top: 0;
}

.background_patient {
  background-image: url(../../assets/images/Login/pacient.webp);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  background-color:#f0f1f5;

}



.background_specialist {
  background-image: url(../../assets/images/Login/specialist.webp);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #8d8a8a;
  margin: 20px 0px;
}

.input_login {
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 0.01%);
  border-width: 1px;
  border-style: solid;
  border-color: #939598;
  border-radius: 30px;
  height: 30px !important;
  margin: 15px 0;
  padding: 20px !important;
  font-size: 14px;
}

.input_correo {
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 0.01%);
  border-width: 1px;
  border-style: solid;
  border-color: #939598;
  border-radius: 30px;
  height: 30px !important;
  margin: 15px 0;
  padding: 20px !important;
  font-size: 14px;
}
.label_login {
}
.label_login label {
  margin-top: 7px;
  text-align: center;
  color: #adadad;
}

.back {
  background-image: url(../../assets/icons/tools/back.png);
  height: 30px;
  width: 100px;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  margin-top: -29px;
  margin-left: -38px;
}
.back:hover {
  opacity: 0.8;
}

.lg-label-2 {
  color: #8d8a8a;
  text-align: center;
  font-size: 13px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.register {
  border-left: 2px solid #7746ae;
  padding-left: 22px;
  margin-top: -17px;
}

.register label {
  color: #8d8a8a;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
}

.register label.registertitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #8d8a8a;
  margin-bottom: 10px;
}

.calendar {
  margin-top: 10px;
  background-image: url(../../assets/icons/tools/calendar3.png);
  background-position: 20px center;
  background-repeat: no-repeat;
  padding: 20px 10px;
  background-size: 40px;
}

.dolar {
  background-image: url(../../assets/icons/tools/currency-dollar.png);
  background-position: 90% center;
  background-repeat: no-repeat;
  padding: 20px 50px 20px 10px;
}

.table {
  background-image: url(../../assets/icons/tools/clipboard-check-fill.png);
  background-position: 20px center;
  background-repeat: no-repeat;
  padding: 20px 10px;
  background-size: 40px;
}
.subtract {
  background-image: url(../../assets/icons/tools/Subtract.png);
  background-position: 90% center;
  background-repeat: no-repeat;
  padding: 20px 50px 20px 10px;
}

.unir {
  background-image: url(../../assets/icons/tools/logo_unir_especialista.png);
  background-position: center 90%;
  background-repeat: no-repeat;
  background-size: 200px;
  padding: 10px;
  padding-bottom: 150px;
}


.btnLogin{
  background-color: #fff;
  border: 3px solid var(--purple2);
  border-radius: 1px 1px;
  padding:5px 20px;
  font-size: 16px;
  color: #7045af;
  opacity: .7;

}


.btnLogin:nth-of-type(1) {
  position: relative;
  bottom: 3px;
  border-top-left-radius: 10px;
  
}
.btnLogin:nth-of-type(2) {
  position: relative;
  bottom: 3px;
  left: -2px;
  border-top-right-radius: 10px;

}



