.sideNavContent {
  background: #0b4e8a;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  font-style: normal;
  line-height: normal;
  min-height: 100vh;
  width: 100vw;
}

.logoSideNav {
  width: 100%;
  height: auto;
  border-bottom: 3px solid #9ed3d8;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.headerpanel {
  background: #fff;
  box-shadow: -3px 4px 5px -4px #0b4e8a;
  height: 60px;
  width: calc(100% - 250px) !important; /* Ajusta según el ancho de tu SideNav */
  position: fixed;
  top: 0;
  left: 250px; /* Ancho del SideNav */
  z-index: 1000; /* Asegúrate de que esté por encima del contenido */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.headerpanel .loginname {
  padding-right: 2em;
  font-size: 14px;
  font-weight: bold;
}

.itemspecialist {
  padding-left: 2em;
  font-size: 13px;
}

.logoIconSideNav{
  height: 35px;
}
.containerlogoicon{
  text-align: center;
}