.inmed-sidebar {
  background: #0b4e8a;
  color: #fff;
  font-size: 13px;
  padding: 30px 0;
  font-style: normal;
  line-height: normal;
  font-family: "RobotoRegular" !important;
  height: 100vh;
  width: 250px; /* Ajusta el ancho según tus necesidades */
  position: fixed;
  top: 0;
  left: 0;
}

.inmed-sidebar .ps-sidebar-container {
  background: transparent !important;
}

.inmed-sidebar .ps-menu-button:hover {
  background-color: transparent !important;
}

.inmed-sidebar .ps-menuitem-root {
  padding: 15px 0px;
}

.inmed-sidebar .ps-menu-button {
  color: #fff !important;
  font-family: "RobotoRegular";
}

.inmed-sidebar .ps-menu-label p {
  font-family: "RobotoRegular" !important;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
}

.ps-collapsed .listMenu li > div {
  padding: 10px;
  text-align: center;
}

.ps-collapsed .ps-menu-button {
  padding: 0px;
  font-size: 25px;
  display: block !important;
  width: 100%;
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ps-collapsed .ps-menu-button .ps-menu-icon {
  padding: 0px;
  font-size: 35px;
  width: 100%;
  text-align: center;
  display: contents;
}

.inmed-sidebar .bi-list {
  color: #fff;
}

.ps-collapsed .listMenu {
  padding: 0 0px;
}