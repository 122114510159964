.inputgroup {
  display: block;
  width: 100%;
  margin-bottom: 0px;
}

.inputgroup span {
  display: inline-block;
  background: transparent;
  border: none;
  font-size: 13px;
  padding: 5px;
}
.inputgroup label {
  font-size: 13px;
}
.grid {
  text-align: center;
}
.inputgroup label {
}

.inputgroup .form-check-input {
}

.filter {
  border-bottom: 1px solid #d6c7c7;
  padding-bottom: 5px;
  margin-bottom: 50px;
}
.figure {
  text-align: center;
  box-shadow: 1px 1px 4px 1px #b3b3b3;
  border-radius: 15px;
  padding: 1em;
  min-width: 100%;
  cursor: pointer;
}

.caption {
  font-size: 14px;
  color: #4f2197;
  font-weight: 600;
}
.img {
  height: 200px;
  margin-top: 5px;
border: none;
transition: all .5s;
 
}
.captionbutton {
 
}
.captionbutton:hover {
  opacity: 0.8;
}

.procedute_title {
  color: #0b4e8a;
  border-bottom: 1px solid #0b4e8a;
  line-height: 50px;
  border-bottom-style: dotted;
}
.specialist {
  border: none;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(135deg, #d3d3d3, #5961af12);
  box-shadow: 4px 3px 6px 2px #d9d9ed;
  text-align: center;
  margin-top: 44px;
}
.specialist label{
  font-size: 13px;
}
.specialist_photo {
  width: 100%;
  box-shadow: 1px 1px 9px var(--blue);
}
.buttonse{

}

.figure:hover>.img{
 
  transform:  scale(0.9);
  
}

