.container {
  padding: 30px 50px;
  font-size: 13px;
}
.profile {
  text-align: center;
}
.profile {
  font-size: 13px;
  box-shadow: 1px 0px 11px 1px rgb(91 91 91 / 30%);
  padding: 31px;
  border-radius: 50px;
}
.titleprimary{
  font-size: 20px;
  font-weight: 700;
}
.row {
  padding: 5px 20px;
}
.photo {
  width: 100%;
  height: auto;
  border-radius: 100%;
  margin-bottom: 10px;
}
.title {
  display: block;
  font-weight: 700;
}
.label {
  display: block;
}

.network{
  display: inline-block;
  margin: 5px;
  font-size: 20px;
}

.network:hover i,.networkWeb:hover i,.networkWeb:hover {
  color: #3851a2;
}

.fav,.share{
cursor: pointer;
display: inline-block;
margin: 0px 10px;
}

.fav:hover i,.fav:hover label,.share:hover i,.share:hover label{
  color: #3851a2;
  cursor: pointer;
}
.networkWeb{
  text-decoration: underline;
  margin-bottom: 15px;
}
.Col{
  
}
.fullName {
  font-size: 30px;
  font-weight: 700;
  color: #3851a2 !important;
  text-align: center;
}

.attributes {
  margin-top: 50px;
  font-size: 14px;
}

.titleatributes {
  font-weight: bold;
  padding-left: 16px;
  display: inline-block;
}
.icon {
  display: inline-block;
  width: 21px;
  height: 20px;
  position: absolute;
  margin-left: -6px;
  font-size: 15px;
  color: var(--blue);
}

.container ul > li {
  padding: 0px 0px;
}

.container ul {
  padding-left: 1rem;
  list-style-type: circle;
}
.localice {
}

.liresponsive{

}

.containerInfo{
  height: 69vh;
  overflow: auto;
}
.containerCalendar{
  border-left: 2px solid #6666664a;
}
.localice h5 {
  font-weight: bold;
}

.qualification{
  display: block;
}

.fieldsetCustom{
  border: 1px solid #d9d9d9ba;
  padding: 20px;
  position: relative;
  text-align: justify;
  box-shadow: 1px 1px 6px 0px #6666663b;
}


.titleprocedure{
  font-weight: 600;
  color: #616161;
}
.legendCustom {
  position: absolute;
  top: -14px;
  background-color: white;
  padding: 0 5px;
  width: auto;
  left: 10px;
  color: #000;
  font-size: 16px;
}
 .labelDesc{
  color: #858585;
 }

.content {
  margin-top: 20px; 
}