.item_hours span {
  background: #9a45a563;
  padding: 5px 10px;
  width: 99%;
  display: inline-block;
  margin: 7px;
  text-align: center;
  border-radius: 100px;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}

.item_hours span:hover {
  opacity: 0.9;
}

.item_hours .seleted {
  background: #7a45ac;
  color: #fff;
  box-shadow: 1px 1px 8px #7a45ac;
}

.btn-reserva {
  position: relative;
  right: 0px;
  float: right;
  margin-top: 20px;
}
