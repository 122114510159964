.topCarousel {
  background: transparent;
  text-align: center;
}

.content {
  height: 400px;
}

.card {
  background: transparent;
  width: 219px;
  display: inline-block;
  height: 360px;
  margin: 1rem 1rem;
  cursor: pointer;
  transform: scale(1);
  transition: 0.5s;
}
.card:hover {
  transform: scale(1.1);
}
.image {
  width: 93px;
  height: 93px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  position: relative;
  left: 63px;
  top: 50px;
}
.image > img {
  width: 73px;
  height: 73px;
  border-radius: 100px;
  margin: 10px;
  filter: drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.3));
}

.container {
  width: 219px;
  height: 222px;
  padding: 20px;
  background: url(../../assets/images/carousel/top/background.png);
  background-repeat: no-repeat;
  background-size: contain;
  font-style: normal;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  padding-top: 64px;
  color: #8d8a8a;
}
.title {
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  display: block !important;
}

.card div {
  display: flex;
  flex-wrap: wrap;
}
.btn {
  position: relative;
  bottom: 19px;
  left: 42px;
}
.item_ubicacion {
  background-image: url(../../assets/images/Ubicacion.png);
  background-position: left top;
  background-repeat: no-repeat;
  padding: 0px 25px;
}
