@import "bootstrap/dist/css/bootstrap.min.css";
@import url(_varibles.scss);
@import url(ant-override.scss);

:root {
  --fc-border-color: #ced2ff;
  --yellow: #fff5e3;
  --blue: #2b3595;
  --purple2: #d64697;
  --pink: #e14594;
  --pink-blue: linear-gradient(90deg, #e14594 0%, #7045af 100%);
  --purple: #7045af;
  --purple-blue: linear-gradient(90deg, #7045af 0%, #2b3595 100%);
  --bs-font-sans-serif: "RobotoRegular", system-ui, -apple-system, "Segoe UI",
    Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: "RobotoRegular", system-ui, -apple-system, "Segoe UI",
    Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-body-font-size: 0.8rem;
  --bs-body-font-weight: 100;
  --fc-today-bg-color: #dadada;
}

@font-face {
  font-family: "RobotoRegular";
  src: url(../assets/fonts/OpenSans-Regular.ttf) format("truetype");
  line-height: 1px;
}

body {
  margin: 0;
  padding: 0;
}

.imgHomeHeader {
  position: absolute;
  height: 470px;
  width: 100%;
}

.imgHomeHeader::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url(../assets/images/home/imgHome.webp),linear-gradient(90deg, rgba(91, 139, 194, 1) 0%, rgba(102, 151, 207, 1) 26%, rgba(97, 144, 199, 1) 47%, rgba(99, 151, 210, 1) 68%, rgb(65 112 158) 88%)
  ;
  background-size:  contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  opacity: 0.9; /* Ajusta la opacidad según desees */
}
.imgHomeHeader {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 470px;
  width: 100%;
 
}

.navbar {
  z-index: 3;
  justify-content: space-between;
}

.navbar-logo {
  margin: 5px 40px;
}

.navbar .menu .navbar-brand {
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 2px solid transparent;
  transition: 0.3s ease;
}

.navbar .menu .navbar-brand:hover {
  border-bottom: 2px solid #fff;
}

.btn {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0px 10px;
  min-width: 110px;
  height: 35px;
}

.btn:hover {
  box-shadow: 1px 1px 5px 2px #9e45a4;
}

.button-primary {
  background: linear-gradient(90deg, #7045af 0%, #2b3595 100%);
  border-radius: 30px;
}

.button-secundary {
  background: linear-gradient(90deg, #e14594 0%, #7045af 100%);
  border-radius: 30px;
}
.blue {
  background: #343e9dd1;
}

.pagination {
  align-items: center;
  justify-content: center;
}

.pagination .page-link {
  border-radius: 31px !important;
  height: 30px;
  width: 30px;
  padding: 5px 10px;
  font-size: 13px;
  text-align: center;
  margin: 0px 8px;
  background: #3993e4;
  color: #fff;
  font-weight: 500;
  border: none;
  opacity: 0.8;
}
.pagination .active > .page-link {
  opacity: 1;
  background: #0b4e8a;
}
.border-divider {
}

.inputSearch {
  border-radius: 50px;
  padding: 5px 0px;
  height: 60px;
  margin-top: 65px;
}
.inputSearch .Mui-focused {
  color: #7045af !important;
  transform: translate(1px, 1x) scale(0.9);
  top: 10px;
}

.home-info {
  padding: 2em 10em;
}

.home-info h4 {
  color: #530b83;
  font-weight: 500;
  margin-bottom: 30px;
}

.home-content {
  background: url(../assets/icons/tools/home-background.webp);
  margin-top: 11.5em;
  padding-top: 20em;
  padding-bottom: 10em;
  background-size: cover;
}

.carouselTop {
  position: absolute;
  z-index: 10;
}

.carousel-indicators{
  display: none;
}
.carousel-indicators button {
  background-color: #530b83 !important;
}

.btn-primary {
  border-radius: 30px;
  background: linear-gradient(90deg, #e14594 0%, #7045af 100%);
}

.btn-procedures {
  margin: 15px;
  padding: 10px 20px;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-1.9rem) translateX(2.15rem);
  color: #7045af;
  font-weight: 500;
}
label {
  font-size: 14px;
}
.text-muted {
  color: #2b3595 !important;
  margin-top: 15px;
  opacity: 1;
  font-weight: 600;
}
.form-control {
  border-radius: 30px;
  font-size: 12px;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #7045af;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #7045af5e;
}

.btn-loginOn {
  height: 40px;
  line-height: 40px;
  padding: 0px 20px;
}

.item-login .dropdown-menu {
  background-color: #fff;
}

.item-login .dropdown-menu a {
  font-size: 13px;
  line-height: 25px;
  color: #212529 !important;
}

.item-login .dropdown-menu i {
  margin-right: 5px;
  color: #7645ae;
  font-size: 17px;
}

.item-login .dropdown-menu a:hover,
.item-login .dropdown-menu a:active {
  background-color: #ebebebbf;
}

.input-autocomplete .MuiInputBase-root {
  font-size: 12px;
  color: black;
  height: 35px;
  border-radius: 64px;
  margin-top: 6px;
  padding: 0px 10px !important;
}

.input-autocomplete .MuiInputLabel-root {
  font-size: 12px;
  color: rgb(53, 53, 53);
}

.input-autocomplete .MuiInputLabel-root.Mui-focused {
  font-size: 13px;
  color: black;
  margin-top: 5px;
}
.input-autocomplete .MuiInputLabel-outlined {
  margin-top: 7px;
  font-size: 13px;
}

.input-autocomplete .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #ced4da !important;
}

.input-autocomplete
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #7045af !important;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #7045af5e;
}

.MuiAutocomplete-popper {
  background-color: #fff;

  border-radius: 4px;
  padding: 8px;
}

.MuiAutocomplete-listbox {
  padding: 0;
  list-style: none;
}

/* Estilo para cada elemento de la lista */
.MuiAutocomplete-option {
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
}

/* Estilo para el elemento resaltado del menú emergente */
.MuiAutocomplete-option[data-focus="true"] {
  background-color: #f1f1f1;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 12px;
  color: #6c757d;
}

.calendar-search .select-calendar,
.calendar-search .select-calendar:focus,
.calendar-search .select-calendar:hover {
  width: 160px;
  border: none !important;
  padding: 15px;
  box-shadow: none;
  cursor: pointer;
}

.title-date {
  font-size: 13px;
}
.time-date {
  font-size: 13px;
  color: #07057c;
  text-align: center;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin: 7px 0;
  cursor: pointer;
  padding: 3px 0;
}
.time-date:hover {
  font-weight: 500;
}

.time-date {
  &.selected {
    background: linear-gradient(90deg, #e14594 0%, #7045af 100%);
    border-radius: 5px;
    color: #fff;
  }
}

.specilist-search .carousel-indicators {
  display: none;
}

.specilist-search .carousel-control-prev,
.specilist-search .carousel-control-next {
  height: 30px;
  background: #7945ad;
  width: 30px;
  top: -35px;
  border-radius: 100px;
  background-size: 10px;
}
.specilist-search .carousel-control-prev-icon,
.specilist-search .carousel-control-next-icon {
  background-size: 14px;
}

.loading {
  background-image: url(../assets/loading.gif);
  background-repeat: no-repeat;
  background-position: center;
}

.table-search-calendar {
  border: 1px solid #dee2e6;
  text-align: center;
}

.table-search-calendar th {
  font-size: 13px;
  color: #0b4e8a !important;
  font-weight: 500;
}
.table-search-calendar th,
.table-search-calendar td {
  border: 1px solid #dee2e6;
}

.table-search-calendar td {
  vertical-align: top;
}

.table-search-calendar tbody tr:first-child td {
  border-top: none;
}

.listMenu {
  padding: 0 15px;
}
.listMenu li {
  display: block;
}
.listMenu li > div {
  display: block;
  padding: 10px;
  cursor: pointer;
  border-radius: 50px;
}
.listMenu li > div:hover {
  background-color: rgba(26, 109, 184, 0.74);
}
.listMenu li a {
  text-decoration: none;
  color: #fff;
}
.listMenu li i {
  padding: 10px;
  font-size: 15px;
}

.listMenu hr {
  margin: 5px;
}

.listMenu li > ul {
  background-color: #13416b;
  border-radius: 10px;
  padding-left: 0px;
  font-weight: 100;
  font-size: 12px;
}

.MuiPickersDay-root.Mui-selected {
  color: #fff;
  background-color: #5819d2 !important;
}
.form-select {
  font-size: 12px;
  border-radius: 100px;
}

.form-check-input {
  cursor: pointer;
}
.form-check-input:checked {
  background-color: #7845ad;
  border-color: #7845ad;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(115 69 175 / 25%);
}

.pageResponsePayu .response-container {
  margin-top: 50px;
  border: 2px solid #ccc;
  padding: 20px;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.pageResponseSite .response-container {
  text-align: left;
}

.pageResponsePayu .imagen-container {
  background: #4160a3;
  width: 100%;
  height: 80px;
}

.pageResponsePayu .imagen-container img {
  width: auto;
  padding: 15px;
  height: 100%;
}

.pageResponsePayu .imagen-container .response-title {
  font-size: 20px;
  color: #fff;
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 25px;
  font-weight: 500;
}

.bold {
  font-weight: 500;
}

.btnclean,
.btnadd,
.btndelete {
  border: none;
  background: transparent;
}

.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.input-wrapper input {
  padding-right: 20px;
  cursor: pointer;
}

.input-wrapper .btnclean {
  position: absolute;
  right: 1px;
  top: 47%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: default;
}

.input-wrapper .btnclean i {
  font-size: 11px;
}

.tableschedule .title,
.tableschedule th {
  font-size: 13px;
  text-align: center;
  color: #3f1375 !important;
}

.tableschedule {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: #7445ae33;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  font-size: 13px;
  text-align: center;
  border-radius: 10px;
}

.input-group {
  margin-bottom: 10px;
}

.input-group .form-label {
  margin-left: 5px !important;
}
.contain_add {
  display: flex;
  align-items: center;
}

.btnadd {
  background: none;
  border: none;
  cursor: pointer;
}

.btnadd i {
  color: #7a45ad;
  font-size: 35px;
}

.btndelete {
  color: #7a45ad;
  font-size: 20px;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
  margin-top: 1em;
}

.nav-tabs .nav-link {
  color: #13416b;
  background: #f3f3f3;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #13416b;
  border-color: #13416b;
}

.fill-primary {
  color: #cc00ff;
  position: relative;
  left: -25px;
  cursor: pointer;
}

.list-group-item {
  font-size: 12px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 12px;
}

.btnicon {
  background-color: transparent;
  border: none;
  height: 30px;
  width: 30px;
  display: inline;
  text-align: center;
  width: 100%;
}

.colpanel {
  border: 1px solid #bbb;
  border-radius: 10px;
  min-height: 35vh;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  font-size: 16px;
  color: #fff;
}

.modal-header {
  background: #004683;
}

.title-form {
  color: #004683;
  padding: 5px 10px;
}

.profilecontainer {
  min-height: auto;
  overflow: auto;
  max-height: 300px;
  padding-bottom: 15px;
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 600px;
}

.btn-calendar-open {
  border: 1px solid #004683;
  background-color: #004683;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 13px;
}
.btn-calendar-open i {
  font-size: 16px;
}

.fc .fc-button-group > .fc-button {
  background-color: #3f1375;
  border-color: transparent;
}

.fc .fc-button-primary {
  background-color: #fff !important;
  border-color: #3f1375 !important;
  color: #3f1375 !important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #3f1375 !important;
  border-color: #fff !important;
  color: #fff !important;
}

.fc thead a {
  color: #ffffff;
  text-decoration: none;
  font-size: 13px;
  font-weight: 100;
  padding: 5px !important;
}
.fc thead > tr {
  background-color: #3f1375;
}

.clickable-event {
  cursor: pointer;
}

.fc-weekday{
  background: #e6e6e6;
  }
  
  .fc-weekend{
  background: rgb(224, 81, 81);
  }

  .fc-holiday{
    background-color: #ebc1ff;
    background-image: url('../assets/icons/festivo.webp');
    background-size: 35%; 
    background-position: center;
   background-repeat: no-repeat;
    }
  
    .fc-holiday .fc-daygrid-day-top::after {
      content: "Festivo";
      padding: 3px;
      font-weight: bold;
      font-style: italic; 
       width: 100%; 
        font-size: 12px; 
    }

    .fc .fc-daygrid-day-number{
      color: #000000;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    }
.loginactive {
  border-bottom: 0px !important;
  padding-bottom: 8px !important;
  text-shadow: 0px 0px 1px #8a45a9;
  opacity: 1 !important;
}

.specialistActive {
  text-align: end;
}
.specialistActive button {
  left: 4px !important;
}
.specialistActive .loginactive {
  left: 0px !important;
}

.registre-title {
  font-size: 16px;
  padding-left: 5px;
}

.customTimePicker {
  width: 100%;
}
.customTimePicker .MuiInputBase-root {
  border-radius: 30px;
}
.customTimePicker .MuiInputBase-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.customTimePicker
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  color: #212529;
  border-color: #7045af;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #7045af5e;
}

.customTimePicker
  .MuiOutlinedInput-root.Mui-disabled:hover
  .MuiOutlinedInput-notchedOutline {
  color: #212529;
  border-color: #444444;
  outline: 0;
  box-shadow: none;
}

.MuiPickersPopper-root .MuiClockPointer-root,
.MuiPickersPopper-root .MuiClock-pin {
  background-color: #7045af;
}

.MuiPickersPopper-root .MuiClock-clock {
  background-color: rgb(21 18 255 / 23%);
}

.MuiPickersPopper-root .MuiClockPointer-thumb {
  border: 16px solid #7045af;
}

.btnSearchIcon {
  min-width: 30px;
  margin: 0px;
}

.tabAsignado {
}

.modalAppointment.show .modal-dialog {
  max-width: 50%;
}

.modalAppointment.show .modal-content {
}

.modalAppointment.show .modal-body {
  padding: 0;
}


.modalAppointmentPatients.show .disabled{
display: none;
}

.modalAppointmentPatients.show .modal-dialog {
  max-width: 35%;
}

.modalAppointmentPatients.show .modal-content {
}

.modalAppointmentPatients.show .modal-body {
  padding: 0;
}


.modalAppointmentPatients.show .disabled{
display: none;
}


.title-form-appoiment{
  color: #004683;
  padding: 5px 10px 0px 0px 
}

.btnAppointment{
  
}

.language-selection {
  display: flex;
  align-items: center;
}

.language-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('/public/resources/images/es.png') no-repeat right center;
  padding-right: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.language-select option {
  background-color: white;
  padding: 10px;
}

.language-flag {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.icon-button{
  cursor: pointer;
  font-size: 12px;
  color: #757575;
}

.icon-button:hover{
  color: #3f1375;
}

.btn-close{
  background: url(../assets/icons/close.svg);
  background-repeat: no-repeat;
    font-size: 10px;
    background-position: center;
}



.icon-notification{
  font-size: 15px;
  color: #0b4e8a;
  padding: 6px 19px;
  cursor: pointer;
  transform: scale(1); 

  transition: transform 0.1s;
}

.icon-notification span{
  font-size: 10px;
  color: #ffffff;
  background: red;
  border-radius: 107px;
  min-width: 9px;
  display: inline-block;
  height: 15px;
  text-align: center;
  font-style: normal;
  position: relative;
  top: -8px;
  left: -6px;
  font-weight: bold;
  padding: 0 5px;
   
}
.icon-notification:hover{
  transform: scale(1.1); 
}

.item-notification{
  background: #ffffff;
    margin: 0px;
    border-top: 1px solid #dbdbdb;
    padding: 15px;
}
.close-notification{
  background: none;
    border: none;
    font-size: 21px;
    position: absolute;
    right: 20px;
}
.header-notification{
  background: #0b4e8a;
    color: #fff;
    font-size: 16px;
}

.title-notification{
  font-size: 14px;
    font-weight: 600;
    margin-bottom: 7px;
    color: #3d3d3d;
}

.button-search{
  background-color: transparent;
  border:none;
}

.profileIcon {
  font-size: 20px;
}

.profileback{
  font-size: 2rem;
}


.scroll-container::-webkit-scrollbar {
  width: 8px; /* Ancho del scrollbar */
}

/* Estilos para el track (fondo del scrollbar) */
.scroll-container::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Color de fondo del track */
  border-radius: 4px; /* Radio de borde del track */
}

/* Estilos para el thumb (la parte del scrollbar que se arrastra) */
.scroll-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color del thumb */
  border: 2px solid #ccc; /* Borde del thumb */
  border-radius: 4px; /* Radio de borde del thumb */
}

/* Cambiar el color del thumb cuando está siendo arrastrado */
.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* Cambia el color del thumb al pasar el ratón sobre él */
}
 strong {
  font-weight: 700;
}

#Loadcontenedor{ 
  background-color: #5f5f5f4f;
  place-content: center;
  height: 100vh;
  color: #000;
  position: fixed;
  width: -webkit-fill-available;
  top: 0;
  display: grid;
  left: 0px;
}
#Loadcontenedor .cargando{ 
  position: relative; 
  color: #FFF;
  margin-top: 2em;
  letter-spacing: 0.08em;
  text-transform: uppercase
}

#Loadcontenedor .rueda {
  border:10px solid rgb(11 78 138);
  border-radius: 50%;
  border-left-color: transparent;
  width: 80px;
  height: 80px;
  animation: giro 1s linear infinite;
}

@keyframes giro {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.procedure-filter {
  border-right: 1px solid rgba(143, 143, 143, 0.493);
  padding-top: 5px;
}

.procedure-w-title{
  color: #3f1375;
  font-weight: bold;
  cursor: pointer;
}


.procedure-filter i{

  font-size: 16px;
}

.autocompleteinmed{

}
   

.autocompleteinmed .MuiFormLabel-root {
  font-family: var(--bs-font-sans-serif);
    line-height: initial;
    font-size: 12px;
    color: rgb(0 0 0 / 47%);
}
.autocompleteinmed .MuiFormLabel-root.Mui-focused {

    color: #3f1375;
}

.autocompleteinmed .MuiOutlinedInput-root {
  font-family: var(--bs-font-sans-serif);
  line-height: initial;
  font-size: 12px;
  color: rgb(0 0 0 / 47%);
  padding: 5px !important;
}

.autocompleteinmed .MuiOutlinedInput-notchedOutline {
  border-color: #2e2e2e4b !important;
}

.text-end {
  text-align: right;
}

/*questions*/
.question-container{

}
.question-container .logo-container {
  text-align: center;
  color: #3d3d3d;
}
.question-container .logo-container img{
  width: 30%;
  padding: 30px 0px;
}

.question-container  .question-options {
  text-align: center;
}
.question-container  .rating-star .star-icon-container svg{
  width: 1.5em;
  height: 1.5em;
  color: #0087ff;
  cursor: pointer;
}

.question-container .check-icon{
  color: #0087ff;
  min-width: 30px;
}

.question-container .option-item{
  min-width: 5%;
  display: block;
  text-align: center;
  background-color: #ffffff;
  margin: 5px;
  border-radius: 10px;
 
  border: 2px solid #232929;
}
.question-container .option-item:hover {
  opacity: 1;
  transform: scale(1.1);
  transition: 0.20s;
}

.question-container .option-item.Mui-selected {
  opacity: 1;
  background-color: #0000002e !important;
  color: #000;
  font-weight: bold;
}
.question-container .options-list{
  padding: 40px 0px;
  display: flex;
}

.question-container .item-icon{
  min-width: 32px;
}

.question-container .question-card{
  border-bottom: 2px solid #b7b7b7;
}
.submit-button-container{
  padding: 10px;
  text-align: center;
}

.question-container .question-text{
  text-align: center;
  font-weight: 600;
  color: #757575;
  padding-top: 27px;
}

.question-container  .question-options2{
  display: block;
  width: 100%;
}

.question-container  .option-text .MuiListItemText-primary{
  font-size: 0.8rem;
}